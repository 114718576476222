import React, { FunctionComponent, useState } from "react"
import { TableRow, TableCell, Select, MenuItem, Grid, Tooltip, Fab, Zoom, IconButton, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"
import { StopInfo, StopFilllevelMaterial } from "../../../../api/graphql/queries/get-tour-stats-with-id"
import { DeparturePoint } from "../../../../api/graphql/queries/get-departure-points"
import lodash from "lodash"
import { getFilllevel } from "../../../../utils/stops"
import { Add, Delete } from "@mui/icons-material"
import { STOP_TYPE } from "../../../../api/graphql/graphql-global-types"
import { StopTableRowHintCell } from "./stop-table-row-hint-cell"

export interface EditableStopsTableRowProps {
  stopInfo: StopInfo
  departurePoints: DeparturePoint[]
  materials: StopFilllevelMaterial[]
  onStopChanged?: (stop: StopInfo) => void
  isNewCollectionPointAllowed: boolean
  showHintColumn: boolean
  onClickNewCollectionPoint?: (sequenceNumber: number) => void
  onClickDeleteCollectionPoint?: (sequenceNumber: number) => void
}

export const EditableStopsTableRow: FunctionComponent<EditableStopsTableRowProps> = (props) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const {
    stopInfo,
    departurePoints,
    onStopChanged,
    materials,
    isNewCollectionPointAllowed,
    onClickNewCollectionPoint,
    onClickDeleteCollectionPoint,
    showHintColumn,
  } = props
  const [isRowHovered, setIsRowHovered] = useState<boolean>(false)
  const [isFabHovered, setIsFabHovered] = useState<boolean>(false)

  const getDeparturePoint = (id: number) => departurePoints.find((dp) => dp.id === id)

  const renderDeparturePointSelect = (id: number, sequence_number: number) => (
    <Select
      value={getDeparturePoint(id)?.id}
      onChange={(event) => onDeparturePointSelectionChanged(Number(event.target.value), sequence_number)}
      fullWidth
    >
      {departurePoints.map((dp) => (
        <MenuItem key={`dp_${dp.id}`} value={dp.id}>
          {dp.name}
        </MenuItem>
      ))}
    </Select>
  )

  const onDeparturePointSelectionChanged = (id: number, sequence_number: number) => {
    const dp = getDeparturePoint(id) as DeparturePoint
    if (lodash.isFunction(onStopChanged)) {
      onStopChanged({
        ...stopInfo,
        id: dp.id,
        lat: dp.latitude,
        lng: dp.longitude,
        name: dp.name,
      })
    }
  }

  return (
    <TableRow onMouseEnter={() => setIsRowHovered(true)} onMouseLeave={() => setIsRowHovered(false)}>
      <TableCell>{stopInfo.sequence_number + 1}</TableCell>
      <TableCell>{stopInfo.type === "collection_point" ? stopInfo.id : undefined}</TableCell>
      <TableCell>{stopInfo.type === "collection_point" ? stopInfo.locationNumber : undefined}</TableCell>
      <TableCell>{t(`tour_overview.stop_types.${stopInfo.type.toUpperCase()}`)}</TableCell>
      <TableCell>
        {stopInfo.type === "departure_point"
          ? renderDeparturePointSelect(stopInfo.id, stopInfo.sequence_number)
          : stopInfo.name}
      </TableCell>
      <TableCell align="center">
        {!stopInfo.postal && !stopInfo.place && !stopInfo.town && !stopInfo.street ? (
          "-"
        ) : (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {stopInfo.street || "-"}
            </Grid>
            <Grid item xs={12}>
              {stopInfo.postal || stopInfo.town || stopInfo.place
                ? `${stopInfo.postal || ""} ${stopInfo.place || stopInfo.town || ""}`
                : "-"}
            </Grid>

            {stopInfo.place && stopInfo.town && stopInfo.place !== stopInfo.town && (
              <Grid item xs={12}>
                {stopInfo.town}
              </Grid>
            )}
          </Grid>
        )}
      </TableCell>
      {showHintColumn && (
        <TableCell align="center">
          <StopTableRowHintCell stopInfo={stopInfo} />
        </TableCell>
      )}
      {!lodash.isEmpty(materials) &&
        materials.map((material) => (
          <TableCell align="center" key={`material_${material.id}`}>
            {getFilllevel(stopInfo, material)}
          </TableCell>
        ))}
      <TableCell align="center">
        <IconButton
          onClick={() =>
            lodash.isFunction(onClickDeleteCollectionPoint)
              ? onClickDeleteCollectionPoint(stopInfo.sequence_number)
              : null
          }
          disabled={
            ![STOP_TYPE.COLLECTION_POINT.toLowerCase(), STOP_TYPE.DISPOSAL_MERCHANT.toLowerCase()].includes(
              stopInfo.type.toLowerCase(),
            )
          }
        >
          <Delete />
        </IconButton>
      </TableCell>
      <TableCell sx={{ position: "relative", border: "none", minWidth: "0!important" }}>
        {isNewCollectionPointAllowed && (isRowHovered || isFabHovered) && (
          <Zoom in timeout={500}>
            <Tooltip placement="left" title={t("tour_overview.table.add_collection_point") as string}>
              <Fab
                sx={{
                  position: "absolute",
                  left: -parseFloat(theme.spacing(2.5)),
                  bottom: -parseFloat(theme.spacing(2.5)),
                  zIndex: theme.zIndex.modal,
                }}
                color="primary"
                size="small"
                onMouseEnter={() => setIsFabHovered(true)}
                onMouseLeave={() => setIsFabHovered(false)}
                onClick={() =>
                  lodash.isFunction(onClickNewCollectionPoint)
                    ? onClickNewCollectionPoint(stopInfo.sequence_number)
                    : null
                }
              >
                <Add />
              </Fab>
            </Tooltip>
          </Zoom>
        )}
      </TableCell>
    </TableRow>
  )
}
