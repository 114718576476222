import { Image as ImageIcon } from "@mui/icons-material"
import { Grid, IconButton, TableCell, TableRow, Tooltip } from "@mui/material"
import lodash from "lodash"
import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"
import { ExportReason } from "../../../../api/graphql/graphql-global-types"
import { StopFilllevelMaterial, StopInfo } from "../../../../api/graphql/queries/get-tour-stats-with-id"
import { FIXED_UNLOAD_INTERVAL_ON_DEMAND } from "../../../../utils/constants"
import { getFilllevel } from "../../../../utils/stops"
import { StopTableRowHintCell } from "./stop-table-row-hint-cell"

interface IStopsTableRowProps {
  index: number
  tourId?: number
  stopInfo: StopInfo
  onRowClicked?: (id: number) => void
  selectedStopId?: number
  materials: StopFilllevelMaterial[]
  onImageIconClicked: (index: number) => void
  showReasonColumn: boolean
  showHintColumn: boolean
  sequenceNumber: number
}

export const StopsTableRow: FunctionComponent<IStopsTableRowProps> = (props) => {
  const { t } = useTranslation()
  const {
    index,
    tourId,
    stopInfo,
    onRowClicked,
    selectedStopId,
    materials,
    onImageIconClicked,
    showReasonColumn,
    showHintColumn,
    sequenceNumber,
  } = props

  const areImagesAvailable = !lodash.isEmpty(stopInfo.collection_point_images)
  const areImagesFromThisTour =
    areImagesAvailable && tourId && stopInfo.collection_point_images?.some((image) => image.tour_id === tourId)

  return (
    <TableRow
      hover
      onClick={() => (lodash.isFunction(onRowClicked) ? onRowClicked(stopInfo.stop_id) : null)}
      selected={!lodash.isNil(selectedStopId) && selectedStopId === stopInfo.stop_id}
    >
      <TableCell>{sequenceNumber}</TableCell>
      <TableCell>{stopInfo.type === "collection_point" ? stopInfo.id : undefined}</TableCell>
      <TableCell>{stopInfo.type === "collection_point" ? stopInfo.locationNumber : undefined}</TableCell>
      <TableCell>{t(`tour_overview.stop_types.${stopInfo.type.toUpperCase()}`)}</TableCell>
      <TableCell>{stopInfo.name}</TableCell>
      <TableCell align="center">
        {!stopInfo.postal && !stopInfo.place && !stopInfo.town && !stopInfo.street ? (
          "-"
        ) : (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {stopInfo.street || "-"}
            </Grid>
            <Grid item xs={12}>
              {stopInfo.postal || stopInfo.town || stopInfo.place
                ? `${stopInfo.postal || ""} ${stopInfo.place || stopInfo.town || ""}`
                : "-"}
            </Grid>

            {stopInfo.place && stopInfo.town && stopInfo.place !== stopInfo.town && (
              <Grid item xs={12}>
                {stopInfo.town}
              </Grid>
            )}
          </Grid>
        )}
      </TableCell>
      {showHintColumn && (
        <TableCell align="center">
          <StopTableRowHintCell stopInfo={stopInfo} />
        </TableCell>
      )}
      {showReasonColumn && (
        <TableCell align="center">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {stopInfo.fixed_unload_interval === FIXED_UNLOAD_INTERVAL_ON_DEMAND
                ? t("tour_overview.export_reasons.on_demand")
                : stopInfo.reason
                  ? t(`tour_overview.export_reasons.${stopInfo.reason}`)
                  : "-"}
            </Grid>
            {stopInfo.reason === ExportReason.INTERVAL_DUE &&
              stopInfo.fixed_unload_interval !== FIXED_UNLOAD_INTERVAL_ON_DEMAND && (
                <Grid item xs={12}>
                  {t("tour_overview.export_reasons.interval", { interval: stopInfo.fixed_unload_interval ?? "-" })}
                </Grid>
              )}
          </Grid>
        </TableCell>
      )}
      {!lodash.isEmpty(materials) &&
        materials.map((material) => (
          <TableCell align="center" key={`material_${material.id}`}>
            {getFilllevel(stopInfo, material)}
          </TableCell>
        ))}
      <TableCell align="center">
        <Tooltip
          placement="top"
          title={
            (areImagesAvailable ? t("tour_overview.table.show_images") : t("tour_overview.table.no_images")) as string
          }
        >
          <span style={{ display: "inline-block" }}>
            {/* span avoids tooltip error caused by disabled iconbutton */}
            <IconButton
              onClick={(event) => {
                event.stopPropagation()
                onImageIconClicked(index)
              }}
              color={areImagesFromThisTour ? "primary" : "secondary"}
              disabled={!areImagesAvailable}
            >
              <ImageIcon />
            </IconButton>
          </span>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}
